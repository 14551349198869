import { library } from '@fortawesome/fontawesome-svg-core'
import { faShare, faUniversity, faCheck, faEnvelope, faLock, faAddressCard, faCode, faTerminal,
  faBook, faPlay, faEllipsisH, faFileAlt, faFolder, faHistory, faVideo, faSave, faClone, faShareSquare,
  faCopy, faFolderOpen, faCog, faPrint, faTimesCircle, faSpinner, faCloudUploadAlt, faStopCircle, faCompress,
  faTimes, faUser, faExclamationCircle, faSearch, faAngleDown, faBars, faKey, faSignOutAlt, faLaptopCode,
  faPlusSquare, faHome, faDownload, faTrashAlt, faFileSignature, faFolderPlus, faFileUpload , faExpand } from '@fortawesome/free-solid-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

let icons = [faShare, faUniversity, faCheck, faGoogle, faEnvelope, faLock, faTimes, faCode, faTerminal,
  faBook, faPlusSquare, faPlay, faEllipsisH, faFileAlt, faFolder, faHistory, faVideo, faSave, faClone, faCompress,
  faShareSquare, faCopy, faFolderOpen, faCog, faPrint, faTimesCircle, faSpinner, faCloudUploadAlt, faStopCircle,
  faUser, faExclamationCircle, faAddressCard, faSearch, faAngleDown, faBars, faKey, faSignOutAlt, faLaptopCode, faHome,
  faDownload, faTrashAlt, faFileSignature, faFolderPlus, faFileUpload, faExpand]
library.add(icons)
